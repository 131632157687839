<template>
  <UiPage
    :title="$t('app.page.network')"
    :margin-bottom="20"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="tabs">
        <TabButton
          :text="$t('views.default.network.rank.name')"
          :value="'Rank'"
          :active="$route.name"
          @click="$router.push({ name: 'Rank' })"
        />

        <TabButton
          :text="$t('views.default.network.network.my-network')"
          :value="'Network'"
          :active="$route.name"
          @click="$router.push({ name: 'Network' })"
        />
      </div>

      <RouterView v-slot="{ Component }">
        <KeepAlive>
          <Component :is="Component" :key="$route.fullPath" />
        </KeepAlive>
      </RouterView>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import TabButton from '@/ui/buttons/TabButton.vue'

export default {
  components: {
    UiPage,
    BackButton,
    TabButton
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .tabs {
    padding: 0 17px;
  }
}
</style>
